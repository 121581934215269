import React, { useState } from "react";
import { motion } from "framer-motion";
import { ColorScheme } from "./Colors";

interface FeedbackFormProps {
  apiKey: string;
  onClose: () => void;
  onSubmit: () => void;
  colors: ColorScheme;
  formTitle: string;
  formSubtitle: string;
  formBorderRadius: string;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  apiKey,
  onClose,
  onSubmit,
  colors,
  formTitle,
  formSubtitle,
  formBorderRadius,
}) => {
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const inputStyle = {
    borderColor: colors.neutral,
    color: colors.baseContent,
    backgroundColor: colors.base200,
  };

  const buttonStyle = {
    backgroundColor: colors.primary,
    color: colors.primaryContent,
    borderRadius: formBorderRadius,
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Implement your feedback submission logic here
    // Use the apiKey for authentication

    await fetch("https://tryformbee.com/api/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        feedback,
        email,
      }),
    });

    setIsSubmitting(false);
    onSubmit();
    onClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8, y: -50 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.8, y: 50 }}
      transition={{ duration: 0.3 }}
      className="shadow-xl p-6 w-full"
      style={{
        backgroundColor: colors.base100,
        color: colors.baseContent,
        borderRadius: formBorderRadius,
        minWidth: "300px",
        maxWidth: "600px",
      }}
    >
      <h2 className="text-2xl font-bold mb-2">{formTitle}</h2>
      <p className="text-sm mb-4">{formSubtitle}</p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="feedback"
            className="block text-sm font-medium"
            style={{ color: colors.baseContent }}
          >
            Your feedback
          </label>
          <textarea
            id="feedback"
            rows={4}
            className="mt-1 block w-full rounded-md shadow-sm focus:ring-2 focus:ring-opacity-50 p-2"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium"
            style={{ color: colors.baseContent }}
          >
            Email (optional)
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 block w-full rounded-md shadow-sm focus:ring-2 focus:ring-opacity-50 p-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={inputStyle}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <motion.button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium rounded-md hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50"
            style={{
              backgroundColor: "transparent",
              color: colors.baseContent,
              border: `1px solid ${colors.neutral}`,
              borderRadius: formBorderRadius,
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Cancel
          </motion.button>
          <motion.button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-white rounded-md hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50 disabled:opacity-50"
            style={buttonStyle}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {isSubmitting ? "Sending..." : "Send"}
          </motion.button>
        </div>
      </form>
    </motion.div>
  );
};

export default FeedbackForm;
